.addComment {
    white-space: "break-spaces" !important;
}

.hrs {
    display: none;
    background-color: gray;
    margin-top: 1rem;
    width: 100% !important;
    height: 1px;
}




@media only screen and (max-width: 576px) {
    .hr {
        display: none;
    }

    .hrs {
        display: block;
    }

    .addComment {
        white-space: nowrap;
    }
}