@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,600;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,600;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,600;0,700;1,500&display=swap);
.arrowButton svg {
    transition: 0.5s;
}

.arrowButton:hover svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}




:root {
    ---offcanvas-width: 270px;
}

.nav-item .navText {
    color: #fff;
    text-decoration: none;
}

/* //--------------------------- */
.active {

    color: #fd624c !important;
}

.sidebar-nav {
    background-color: #343a40 !important;
    color: #fff;
    width: var(---offcanvas-width) !important;
    -webkit-transform: none !important;
            transform: none !important;
    visibility: visible !important;
}



/* Sağ Tarafta Render Edilecek Sayfalar  */

.App {
    margin-left: var(---offcanvas-width) !important;
    background-color: #e4e9ef;
    height: 130vh !important;
}

.Haber {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Satis {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Rapor {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Etkınlık {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Contacts {
    /* bütün sayfalara eklenecek bu renk bgc olarak*/
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Urun {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Calendar {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Ayarlar {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Users {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Nav {

    margin-left: 269px !important;
}

.hamburger {
    display: none !important;
}

.popovers {
    border: none !important;
    display: none !important;
    color: #fd624c;
    font-weight: 900;
}

@media screen and (max-width: 992px) {
    .popovers {
        display: block !important;
    }

    .sidebar-nav {
        margin-left: 0px !important;
    }

    .Nav {
        margin-left: 0px !important;
    }

    .hamburger {
        display: block !important;
    }

    /* 
    .navbar-brand .plus {
        display: block !important;
    } */

    /**/
    .About {
        margin-left: 0px !important;
    }

    .App {
        margin-left: 0px !important;
    }

    .Haber {
        margin-left: 0px !important;
    }

    .Satis {
        margin-left: 0px !important;
    }

    .Rapor {
        margin-left: 0px !important;
    }

    .Etkınlık {
        margin-left: 0px !important;
    }

    .Contacts {
        /* bütün sayfalara eklenecek bu renk bgc olarak*/
        margin-left: 0px !important;
    }

    .Urun {
        margin-left: 0px !important;
    }

    .Calendar {
        margin-left: 0px !important;
    }

    .Ayarlar {

        margin-left: 0px !important;
    }

    .Users {

        margin-left: 0px !important;
    }

}

/*  Nav Contacts Add */
.fade .plusRadius {
    border-radius: 32px;
}


.plusInput {


    border: none;
    border-bottom: 1px solid rgb(102, 93, 93);
    box-sizing: border-box;
    outline: none;
    padding: 0 10px;
    font-size: 14px;
}

.plusInput:nth-child(1) {
    border: none;

}

::-webkit-file-upload-button {
    border: none;
}

.btnAdd {
    background-color: #fd624c;
    border: none;
    color: #fff;
    font-weight: bold;
    padding: 1px 30px;
    border-radius: 16px;
}
h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
p,
span {

    font-family: 'Red Hat Display', sans-serif !important;
}

html::-webkit-scrollbar {
    background-color: #E4E9EF !important;
    width: 0.7vw;
    height: 16px !important;
}

html::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-transition: -webkit-transform 0.9s ease;
    transition: -webkit-transform 0.9s ease;
    transition: transform 0.9s ease;
    transition: transform 0.9s ease, -webkit-transform 0.9s ease;
    background-color: #fd624c !important;
}

button:focus {
    outline: none !important;
}

#custom-css-outlined-input-label {
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
}
.card {
    border-radius: 2rem !important;
}

.card-body {
    border-bottom: 1px solid black !important;
}

.card-body:last-child {
    border: none !important;
}

.card-title {

    color: #fd624c !important;
    font-weight: bold !important;
}

.rounded-circle {
    color: #fff;
    background-color: #fd624c !important;
}

.profile {
    border: 3px solid #fd624c !important;
    border-radius: 50% !important;
}

.containerMobile {
    display: none;
}









@media screen and (max-width: 992px) {
    .Satis-none {
        display: none;
    }


}

@media screen and (max-width: 768px) {
    .containers {
        display: none;
    }

    .containerMobile {
        display: block;
    }

    .rounded-circle {
        background-color: #fff !important;
        border: 2px solid #fd624c !important;
        color: black;
        position: absolute;
        top: -2px;
        left: 8px;
        padding: 3px;

    }

    .accordion-button:not(.collapsed) {
        background-color: #fff !important;
    }

    .card-title {
        color: #feb302 !important;
        font-weight: 900 !important;
    }

    .badges {
        position: relative;
        height: 50px;
        border-radius: 16px;
        background-color: #fff !important;
        padding: 10px;
        z-index: inherit;
    }

    .card,
    .accordion {
        min-width: 95% !important;
    }


}

/* 
@media screen and (max-width: 576px) {

    .card,
    .accordion {
        min-width: 100% !important;
    }
} */
.displayButton {
    display: none;
}

#profile {
    padding: 10px;
}

span {
    color: "red" !important;
}

h4 {
    color: "#323441" !important
}

@media only screen and (max-width: 1200px) {
    .displayButton {
        display: block;
    }
}

@media only screen and (max-width: 576px) {
    .displayButton {
        display: none;
    }

    .adem {
        display: none;
    }

}

@media only screen and (max-width: 405px) {
    #Mobilehr {
        display: none !important;
    }

}
.addComment {
    white-space: "break-spaces" !important;
}

.hrs {
    display: none;
    background-color: gray;
    margin-top: 1rem;
    width: 100% !important;
    height: 1px;
}




@media only screen and (max-width: 576px) {
    .hr {
        display: none;
    }

    .hrs {
        display: block;
    }

    .addComment {
        white-space: nowrap;
    }
}

 /* #fd624c */
 #root>div.Calendar>div>div>div>div>div>div.fc-view-harness.fc-view-harness-active {
     overflow-x: hidden !important;
 }

 #root>div.Calendar>div>div>div>div>div>div.fc-view-harness.fc-view-harness-active {
     background-color: #fff;
     border-radius: 1rem !important;
 }


 #root>div.Calendar>div>div>div>div>div>div.fc-view-harness.fc-view-harness-active>div>table {
     border: none !important;
     outline: none !important;
 }

 .fc-event-today {
     color: #fd624c;
 }

 .fc-event-today:hover {
     color: #fd624c;
 }


 #root .fc-col-header-cell-cushion,
 .fc-daygrid-day-frame {
     text-decoration: none !important;
     color: #fd624c !important;
 }

 .fc-daygrid-day-number {
     color: #fd624c;
     text-decoration: none;
 }

 .fc-daygrid-day-number:hover {
     color: #fd624c;
 }

 .fc-daygrid-day-bottom>a {
     color: #fd624c !important;
 }

 .fc-event-main {
     color: #fff !important;
     background-color: #fd624c !important;
     border: none !important;
     outline: none !important;
 }

 .fc-event-main:active {
     border: none !important;
     outline: none !important;
 }

 .fc-button-group>button {
     background-color: #fd624c !important;
     border: none !important;
     outline: none !important;
     margin: 0 10px !important;
     padding: 5px 30px !important;
     border-radius: 10px !important;
 }

 .fc-toolbar-chunk {
     display: flex;
 }

 @media only screen and (max-width: 768px) {

     .fc-toolbar-chunk {
         margin-top: 1rem;
     }

     .fc-button-group {
         margin-top: 1rem;
     }

     .fc-header-toolbar {
         display: flex;
         flex-direction: column;
     }
 }

 .fc-toolbar-chunk>button {
     background-color: transparent !important;
     border: none !important;
     outline: none !important;
     color: black !important;
 }
html {
    overflow-x: hidden;

    box-sizing: border-box;
    outline: none;
    margin: 0;
}

.bordr {
    border: none;
    border-bottom: 1px solid #e5e5e5 !important;
    ;
    box-sizing: border-box;
    outline: none;
}

.btn-color {
    background-color: #fd624c;

    color: rgb(255, 255, 255);
    padding: 0.7rem;
    border: none !important;

    font-family: 'Red Hat Display', sans-serif;
    border-radius: 10px;
    font-weight: bold;
}


.login-page-form {
    background-color: white;
    -webkit-transform: translateX(-35%);
            transform: translateX(-35%);
    max-width: 400px;
    width: 100%;
    border-radius: 25px;
    padding: 2rem;
}

.haveAnAccount {
    color: #323441;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 500;
    word-wrap: break-word;


}

.forget {
    text-decoration: none;
    color: #323441;
    font-weight: bold;
    font-family: 'Red Hat Display', sans-serif;

}

.signup {
    color: #fd624c !important;
    text-decoration: none;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: bold;
}

.signup:hover {
    color: #fd624c !important;
    ;

}

@media only screen and (max-width: 992px) {
    .login-page-form {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
        max-height: 400px;

    }

    .haveAnAccount {
        margin-top: 7rem;
    }

    .container-fluid {
        background-color: #e4e9ef;
        display: flex;
        justify-content: center;
    }

}
.Nav {
    min-height: 40px !important;
}
.offcanvas-start {
    width: 270px !important;
}
#outlined-textarea {
    /* width: 400px !important; */
    min-height: 150px !important;
    border: 1px solid #fd624c !important;
    border-radius: 7px !important;
    outline: none;
}

#outlined-textareas {
    width: 100% !important;
    min-height: 150px !important;
    border: 1px solid #fd624c !important;
    border-radius: 7px !important;
    outline: none;
}

.MuiSvgIcon-root {
    color: #fd624c !important;
}

#demo-select-small {
    color: #fd624c !important;
}

.MuiOutlinedInput-notchedOutline {
    border: 1px solid #fd624c !important;
    outline: none;
}

/* responsıve  */

@media only screen and (max-width: 992px) {

    #save {
        margin-top: 20px !important;
    }

}
