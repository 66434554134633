.arrowButton svg {
    transition: 0.5s;
}

.arrowButton:hover svg {
    transform: rotate(180deg);
}




:root {
    ---offcanvas-width: 270px;
}

.nav-item .navText {
    color: #fff;
    text-decoration: none;
}

/* //--------------------------- */
.active {

    color: #fd624c !important;
}

.sidebar-nav {
    background-color: #343a40 !important;
    color: #fff;
    width: var(---offcanvas-width) !important;
    transform: none !important;
    visibility: visible !important;
}



/* Sağ Tarafta Render Edilecek Sayfalar  */

.App {
    margin-left: var(---offcanvas-width) !important;
    background-color: #e4e9ef;
    height: 130vh !important;
}

.Haber {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Satis {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Rapor {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Etkınlık {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Contacts {
    /* bütün sayfalara eklenecek bu renk bgc olarak*/
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Urun {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Calendar {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Ayarlar {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Users {
    background-color: #e4e9ef;
    height: 130vh !important;
    margin-left: var(---offcanvas-width) !important;
}

.Nav {

    margin-left: 269px !important;
}

.hamburger {
    display: none !important;
}

.popovers {
    border: none !important;
    display: none !important;
    color: #fd624c;
    font-weight: 900;
}

@media screen and (max-width: 992px) {
    .popovers {
        display: block !important;
    }

    .sidebar-nav {
        margin-left: 0px !important;
    }

    .Nav {
        margin-left: 0px !important;
    }

    .hamburger {
        display: block !important;
    }

    /* 
    .navbar-brand .plus {
        display: block !important;
    } */

    /**/
    .About {
        margin-left: 0px !important;
    }

    .App {
        margin-left: 0px !important;
    }

    .Haber {
        margin-left: 0px !important;
    }

    .Satis {
        margin-left: 0px !important;
    }

    .Rapor {
        margin-left: 0px !important;
    }

    .Etkınlık {
        margin-left: 0px !important;
    }

    .Contacts {
        /* bütün sayfalara eklenecek bu renk bgc olarak*/
        margin-left: 0px !important;
    }

    .Urun {
        margin-left: 0px !important;
    }

    .Calendar {
        margin-left: 0px !important;
    }

    .Ayarlar {

        margin-left: 0px !important;
    }

    .Users {

        margin-left: 0px !important;
    }

}

/*  Nav Contacts Add */
.fade .plusRadius {
    border-radius: 32px;
}


.plusInput {


    border: none;
    border-bottom: 1px solid rgb(102, 93, 93);
    box-sizing: border-box;
    outline: none;
    padding: 0 10px;
    font-size: 14px;
}

.plusInput:nth-child(1) {
    border: none;

}

::-webkit-file-upload-button {
    border: none;
}

.btnAdd {
    background-color: #fd624c;
    border: none;
    color: #fff;
    font-weight: bold;
    padding: 1px 30px;
    border-radius: 16px;
}