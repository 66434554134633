@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,600;0,700;1,500&display=swap');

.displayButton {
    display: none;
}

#profile {
    padding: 10px;
}

span {
    color: "red" !important;
}

h4 {
    color: "#323441" !important
}

@media only screen and (max-width: 1200px) {
    .displayButton {
        display: block;
    }
}

@media only screen and (max-width: 576px) {
    .displayButton {
        display: none;
    }

    .adem {
        display: none;
    }

}

@media only screen and (max-width: 405px) {
    #Mobilehr {
        display: none !important;
    }

}