.card {
    border-radius: 2rem !important;
}

.card-body {
    border-bottom: 1px solid black !important;
}

.card-body:last-child {
    border: none !important;
}

.card-title {

    color: #fd624c !important;
    font-weight: bold !important;
}

.rounded-circle {
    color: #fff;
    background-color: #fd624c !important;
}

.profile {
    border: 3px solid #fd624c !important;
    border-radius: 50% !important;
}

.containerMobile {
    display: none;
}









@media screen and (max-width: 992px) {
    .Satis-none {
        display: none;
    }


}

@media screen and (max-width: 768px) {
    .containers {
        display: none;
    }

    .containerMobile {
        display: block;
    }

    .rounded-circle {
        background-color: #fff !important;
        border: 2px solid #fd624c !important;
        color: black;
        position: absolute;
        top: -2px;
        left: 8px;
        padding: 3px;

    }

    .accordion-button:not(.collapsed) {
        background-color: #fff !important;
    }

    .card-title {
        color: #feb302 !important;
        font-weight: 900 !important;
    }

    .badges {
        position: relative;
        height: 50px;
        border-radius: 16px;
        background-color: #fff !important;
        padding: 10px;
        z-index: inherit;
    }

    .card,
    .accordion {
        min-width: 95% !important;
    }


}

/* 
@media screen and (max-width: 576px) {

    .card,
    .accordion {
        min-width: 100% !important;
    }
} */