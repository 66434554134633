@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,600;0,700;1,500&display=swap');

html {
    overflow-x: hidden;

    box-sizing: border-box;
    outline: none;
    margin: 0;
}

.bordr {
    border: none;
    border-bottom: 1px solid #e5e5e5 !important;
    ;
    box-sizing: border-box;
    outline: none;
}

.btn-color {
    background-color: #fd624c;

    color: rgb(255, 255, 255);
    padding: 0.7rem;
    border: none !important;

    font-family: 'Red Hat Display', sans-serif;
    border-radius: 10px;
    font-weight: bold;
}


.login-page-form {
    background-color: white;
    transform: translateX(-35%);
    max-width: 400px;
    width: 100%;
    border-radius: 25px;
    padding: 2rem;
}

.haveAnAccount {
    color: #323441;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 500;
    word-wrap: break-word;


}

.forget {
    text-decoration: none;
    color: #323441;
    font-weight: bold;
    font-family: 'Red Hat Display', sans-serif;

}

.signup {
    color: #fd624c !important;
    text-decoration: none;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: bold;
}

.signup:hover {
    color: #fd624c !important;
    ;

}

@media only screen and (max-width: 992px) {
    .login-page-form {
        transform: translateX(0%);
        max-height: 400px;

    }

    .haveAnAccount {
        margin-top: 7rem;
    }

    .container-fluid {
        background-color: #e4e9ef;
        display: flex;
        justify-content: center;
    }

}