@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,600;0,700;1,500&display=swap');



h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
p,
span {

    font-family: 'Red Hat Display', sans-serif !important;
}

html::-webkit-scrollbar {
    background-color: #E4E9EF !important;
    width: 0.7vw;
    height: 16px !important;
}

html::-webkit-scrollbar-thumb {
    border-radius: 10px;
    transition: transform 0.9s ease;
    background-color: #fd624c !important;
}

button:focus {
    outline: none !important;
}

#custom-css-outlined-input-label {
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
}