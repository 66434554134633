 /* #fd624c */
 #root>div.Calendar>div>div>div>div>div>div.fc-view-harness.fc-view-harness-active {
     overflow-x: hidden !important;
 }

 #root>div.Calendar>div>div>div>div>div>div.fc-view-harness.fc-view-harness-active {
     background-color: #fff;
     border-radius: 1rem !important;
 }


 #root>div.Calendar>div>div>div>div>div>div.fc-view-harness.fc-view-harness-active>div>table {
     border: none !important;
     outline: none !important;
 }

 .fc-event-today {
     color: #fd624c;
 }

 .fc-event-today:hover {
     color: #fd624c;
 }


 #root .fc-col-header-cell-cushion,
 .fc-daygrid-day-frame {
     text-decoration: none !important;
     color: #fd624c !important;
 }

 .fc-daygrid-day-number {
     color: #fd624c;
     text-decoration: none;
 }

 .fc-daygrid-day-number:hover {
     color: #fd624c;
 }

 .fc-daygrid-day-bottom>a {
     color: #fd624c !important;
 }

 .fc-event-main {
     color: #fff !important;
     background-color: #fd624c !important;
     border: none !important;
     outline: none !important;
 }

 .fc-event-main:active {
     border: none !important;
     outline: none !important;
 }

 .fc-button-group>button {
     background-color: #fd624c !important;
     border: none !important;
     outline: none !important;
     margin: 0 10px !important;
     padding: 5px 30px !important;
     border-radius: 10px !important;
 }

 .fc-toolbar-chunk {
     display: flex;
 }

 @media only screen and (max-width: 768px) {

     .fc-toolbar-chunk {
         margin-top: 1rem;
     }

     .fc-button-group {
         margin-top: 1rem;
     }

     .fc-header-toolbar {
         display: flex;
         flex-direction: column;
     }
 }

 .fc-toolbar-chunk>button {
     background-color: transparent !important;
     border: none !important;
     outline: none !important;
     color: black !important;
 }