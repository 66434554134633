#outlined-textarea {
    /* width: 400px !important; */
    min-height: 150px !important;
    border: 1px solid #fd624c !important;
    border-radius: 7px !important;
    outline: none;
}

#outlined-textareas {
    width: 100% !important;
    min-height: 150px !important;
    border: 1px solid #fd624c !important;
    border-radius: 7px !important;
    outline: none;
}

.MuiSvgIcon-root {
    color: #fd624c !important;
}

#demo-select-small {
    color: #fd624c !important;
}

.MuiOutlinedInput-notchedOutline {
    border: 1px solid #fd624c !important;
    outline: none;
}

/* responsıve  */

@media only screen and (max-width: 992px) {

    #save {
        margin-top: 20px !important;
    }

}